<template>
  <div class="row">
    <div v-if="title" class="col-12">
      <p class="h3">{{ title }}</p>
    </div>
    <div class="col-12">
      <div v-if="success">
        <Alert status="info">Password Cambiata con successo</Alert>
      </div>
      <FormContainer v-else class="row" @submitForm="changePassword">
        <div v-if="error" class="col-12">
          <Alert status="danger">
            <template v-if="error === 'wrong-password'">
              Password attuale errata, si prega di riprovare.
            </template>
            <template v-else>
              Errore di sistema, riprovare più tardi.
            </template>
          </Alert>
        </div>

        <FormInputTextWidget
          class="col-12"
          label="Password Attuale"
          v-model="data.oldPassword"
          :errorMsg="errorHash.oldPassword"
          type="password"
          @input="removeError('oldPassword')"
        />
        <FormInputTextWidget
          class="col-12"
          label="Nuova Password"
          v-model="data.newPassword"
          :errorMsg="errorHash.newPassword"
          type="password"
          @input="removeError('newPassword')"
        />
        <FormInputTextWidget
          class="col-12"
          label="Conferma Password"
          v-model="confirmPassword"
          :errorMsg="errorHash.confirmPassword"
          type="password"
          @input="removeError('confirmPassword')"
        />

        <div v-if="!success" class="col-12 mt-4">
          <div class="row pt-1">
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click.prevent="changePassword">
                Aggiorna
              </button>
            </div>
          </div>
        </div>
      </FormContainer>
    </div>
  </div>
</template>

<script>
import validateMixin from '../../../libs/Form/mixins/validate';

export default {
  name: 'profile-password-change',
  mixins: [validateMixin],
  components: {
    FormInputTextWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormContainer: () => import('../Form/FormContainer'),
    Alert: () => import('../../../libs/Feedback/components/Alert'),
  },
  data () {
    return {
      data: {
        oldPassword: '',
        newPassword: '',
      },
      confirmPassword: '',
      error: false,
      success: null,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Cambia Password',
    },
  },
  methods: {
    changePassword () {
      if (!this.isValid()) {
        return;
      }

      this.$api.changeMyPassword(this.data.oldPassword, this.data.newPassword)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error.response.status === 403) {
            this.error = 'wrong-password';

            return;
          }

          this.error = 'service';
        })
      ;
    },
    isValid () {
      if (!this.data.oldPassword) {
        this.addError('Inserisci la password attuale', 'oldPassword');
      }
      if (!this.data.newPassword) {
        this.addError('Inserisci la nuova password', 'newPassword');
      }
      if (!this.confirmPassword) {
        this.addError('Inserisci la password di conferma', 'confirmPassword');
      }

      if (this.data.newPassword.length < 8 || !this.data.newPassword.match(/\d|[^\w\s]/)) {
        this.addError('La password deve avere almeno 8 caratteri, compreso un numero o un simbolo.', 'newPassword');
      } else if (this.data.newPassword !== this.confirmPassword) {
        this.addError('La password e la password di conferma devono essere uguali', 'confirmPassword');
      }

      return !this.hasErrors;
    },
  },
};

</script>

<style lang="scss">

.page-change-password {
  .card {
    margin: 0 auto;
    padding: 64px 32px;
    max-width: 700px;
  }
}

</style>
